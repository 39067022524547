import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

type PopupBannerProps = {
  onClose: () => void;
};

const PopupBanner: React.FC<PopupBannerProps> = ({ onClose }) => {
  const [isVisible, setIsVisible] = useState(false);
  const [isDimmed, setIsDimmed] = useState(false);
  const newBannerSrc = '/new_iot_banner.jpg'; 

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.pageYOffset;
      const windowHeight = window.innerHeight;
      const bodyHeight = document.body.scrollHeight;
      const scrollPercent = (scrollPosition / (bodyHeight - windowHeight)) * 100;

      if (!isVisible && 
          ((window.innerWidth >= 768 && scrollPercent >= 5) || 
          (window.innerWidth < 768 && scrollPercent >= 10))) {
        setIsVisible(true);
        setIsDimmed(true); // Keep background dimmed when popup appears
        document.body.style.background = 'rgba(0, 0, 0, 0.5)';
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isVisible]);

  useEffect(() => {
    const isBannerClosed = localStorage.getItem('popupBannerClosed');
    if (isBannerClosed === 'true') {
      setIsVisible(false);
      setIsDimmed(false);
      document.body.style.background = ''; 
    }

    const lastClosedTime = localStorage.getItem('popupClosedTime');
    if (lastClosedTime) {
      const currentTime = new Date().getTime();
      const threeHours = 3 * 60 * 60 * 1000; 
      const timeDifference = currentTime - parseInt(lastClosedTime, 10);
      if (timeDifference < threeHours) {
        onClose();
        setIsVisible(false);
        setIsDimmed(false);
        document.body.style.background = '';
      }
    }
  }, []);

  const handleImageClick = () => {
    window.location.href = 'https://iot.dxhub.co.jp/lp';
  };

  const handleClose = () => {
    setIsVisible(false);
    setIsDimmed(false);
    onClose();
    localStorage.setItem('popupBannerClosed', 'true');
    const currentTime = new Date().getTime();
    localStorage.setItem('popupClosedTime', currentTime.toString());
    document.body.style.background = ''; 
  };

  return (
    <>
      {isDimmed && <div className="overlay"></div>}

      {isVisible && (
        <div className={`popup-banner ${isDimmed ? 'bright' : ''}`}>
          <button onClick={handleClose} className="close-button">
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </button>
          <a href="https://mvno.dxhub.co.jp/" onClick={handleImageClick}>
            <img src={newBannerSrc} alt="New Banner" />
          </a>
        </div>
      )}
    </>
  );
}

export default PopupBanner;
